import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Button, Icon, SelectClinic, Text, Theme } from 'common/UI'
import useDebounce from 'common/hooks/useDebounce'
import { useScroll } from 'common/hooks/useScroll'
import { ClinicSearchHeroStoryblok } from 'common/types'
import {
  getClinicDistance,
  getDistance,
  getImageAttributes,
} from 'common/utils/content'
import { useTranslation } from 'lib/i18n'
import { ClinicSearchPanel } from 'modules/Layout/ClinicSearchPanel'

import { countryStrings } from '../ClinicLocator/ClinicLocator'
import { GeolocationStatus } from '../ClinicLocator/types'
import { useFetchClinicLocations } from '../ClinicLocator/useFetchClinics'
import { useMapboxLocation } from '../ClinicLocator/useMapboxLocation'
import { CommonHero, Wrapper, ContentHolder } from './CommonHero'
import { ImageHero } from './ImageHero'

type Props = {
  block: ClinicSearchHeroStoryblok
}

const ClinicSearchHero: React.FC<Props> = ({ block, ...props }) => {
  const { variant } = block
  const [isSearchVisible, setSearchVisible] = useState<boolean>(false)

  const { scrollPosition } = useScroll()
  const containerRef = useRef<HTMLDivElement>(null)

  const {
    query: { lang },
  } = useRouter()
  const { i18n } = useTranslation()
  const [, region] = ((lang as string) || '').split('-')
  const [search, setSearch] = useState('')
  const [geolocationStatus, setGeolocationStatus] = useState<GeolocationStatus>(
    GeolocationStatus.Loading
  )

  const [userLocation, setUserLocation] = useState<{
    lat: number
    lng: number
  } | null>(null)

  useEffect(() => {
    try {
      if (
        'geolocation' in navigator &&
        (window.location.protocol === 'https:' ||
          process.env.NODE_ENV === 'development')
      ) {
        setGeolocationStatus(GeolocationStatus.Ready)
      }
    } catch (error) {
      setGeolocationStatus(GeolocationStatus.Unsupported)
    }
  }, [])

  const disableNearMe = () => {
    setUserLocation(null)
    setGeolocationStatus(GeolocationStatus.Ready)
  }

  const handleNearMe = useCallback(() => {
    if (userLocation) {
      disableNearMe()
      return
    }

    setGeolocationStatus(GeolocationStatus.Loading)

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeolocationStatus(GeolocationStatus.Success)

        setSearch('')

        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      },
      (error) => {
        console.error(error)
        setGeolocationStatus(GeolocationStatus.Error)
      },
      {
        enableHighAccuracy: true,
      }
    )
  }, [userLocation])

  const {
    data,
    isError: clinicLoadingErrored,
    isLoading: isLoadingClinics,
  } = useFetchClinicLocations()

  const debouncedSearch = useDebounce(search)

  const hasSearch = debouncedSearch || userLocation

  const { status: locationStatus, searchedLocation: mapboxSearchedLocation } =
    useMapboxLocation({
      search,
      userLocation,
    })

  const locationStatusIsLoading = locationStatus === 'loading'
  const locationStatusIsError = locationStatus === 'error'

  const clinics = useMemo(() => {
    let clinic = data

    if (!hasSearch && !!region) {
      clinic = clinic
        .filter((c) => c.content.country === region)
        .sort((a) => {
          return a.content.country === region ? -1 : 1
        })
    }

    // Sort Clinics by distance
    if (userLocation) {
      const clinicsWithDistance = clinic.map((clinic) => {
        const coordinates = clinic.content.coordinates.split(',')
        const distance = getDistance(
          Number(coordinates[0]),
          Number(coordinates[1]),
          userLocation.lat,
          userLocation.lng
        )
        return {
          ...clinic,
          distance,
        }
      })

      clinic = clinicsWithDistance.sort((a, b) => {
        return a.distance - b.distance
      })
    }

    return clinic
  }, [data, hasSearch, region])

  const clinicsArray = useMemo(() => {
    const allClinics = clinics?.map((clinic) => {
      const coordinates = clinic.content.coordinates.split(',')
      const distance = getClinicDistance({
        userLocation,
        mapboxSearchedLocation,
        clinicCoordinates: coordinates,
      })

      return {
        id: clinic.id,
        name: clinic.content.name,
        image: clinic.content.image.filename,
        country: countryStrings[clinic.content.country]
          ? i18n(countryStrings[clinic.content.country])
          : null,
        url: clinic.content.website_url || '/',
        distance,
      }
    })

    if (userLocation || mapboxSearchedLocation) {
      return allClinics.sort((a, b) => {
        return (a.distance || 0) - (b.distance || 0)
      })
    }

    return allClinics
  }, [clinics, mapboxSearchedLocation, userLocation])

  const startCordY = !containerRef.current
    ? 0
    : containerRef.current?.offsetTop / 3

  const maxTranslate = 120

  const translateX = !containerRef.current
    ? 0
    : Math.min(
        Math.floor(
          (Math.max(0, scrollPosition - startCordY) /
            containerRef.current?.offsetHeight) *
            maxTranslate
        ),
        maxTranslate
      )

  return (
    <Theme colorTheme="light">
      <Wrapper ref={containerRef} variant={variant} {...props}>
        <ContentHolder heroVariant={variant}>
          <CommonHero block={block} />
          <SearchHolder>
            <SelectClinic
              name="search"
              placeholder={block.search_placeholder}
              value={search}
              onChange={(e) => {
                setSearch(e.currentTarget.value)
              }}
              options={clinicsArray}
              disabledNearMe={locationStatusIsLoading}
              checkedNearMe={!!userLocation}
              onChangeNearMe={handleNearMe}
              geolocationStatusNearMe={geolocationStatus}
              isLoading={
                (isLoadingClinics || locationStatusIsLoading) &&
                !clinicLoadingErrored
              }
              hasError={clinicLoadingErrored || locationStatusIsError}
            />
          </SearchHolder>
          <SearchHolderMobile>
            <Button
              variant="ghost"
              css={{
                marginTop: '1rem',
                marginBottom: '0.5rem',
                width: '100%',
                justifyContent: 'flex-start',
              }}
              onClick={() => setSearchVisible(true)}
            >
              <Icon icon="search" />
              <Text
                as="span"
                color="foreground.subtle"
                css={{ marginLeft: '0.5rem' }}
              >
                {block.search_placeholder}
              </Text>
            </Button>
          </SearchHolderMobile>
        </ContentHolder>
        <WrapperImages
          style={{
            transform: `translateX(-${translateX}px)`,
          }}
        >
          <WrapperRectangularImage>
            <NextImage
              fill
              priority
              src={getImageAttributes(block.squared_image).src}
              alt={getImageAttributes(block.squared_image).alt}
              style={{ objectFit: 'cover', height: '100%' }}
            />
          </WrapperRectangularImage>
          <GradientDiv />
          <WrapperSquareImage>
            <NextImage
              fill
              priority
              src={getImageAttributes(block.rectangular_image).src}
              alt={getImageAttributes(block.rectangular_image).alt}
              style={{ objectFit: 'cover', height: '100%' }}
            />
          </WrapperSquareImage>
          <WrapperRoundedImage>
            <NextImage
              fill
              priority
              src={getImageAttributes(block.rounded_image).src}
              alt={getImageAttributes(block.rectangular_image).alt}
              style={{ objectFit: 'cover', height: '100%' }}
            />
          </WrapperRoundedImage>
        </WrapperImages>
        <ImageHero css={{ top: '20%', zIndex: 8 }} block={block} />
        <ClinicSearchPanel
          isVisible={isSearchVisible}
          onClose={() => {
            setSearchVisible(false)
            setSearch('')
          }}
          placeholder={block.search_placeholder}
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value)
          }}
          disabledNearMe={locationStatusIsLoading}
          checkedNearMe={!!userLocation}
          onChangeNearMe={handleNearMe}
          geolocationStatusNearMe={geolocationStatus}
          isLoading={
            (isLoadingClinics || locationStatusIsLoading) &&
            !clinicLoadingErrored
          }
          hasError={clinicLoadingErrored || locationStatusIsError}
          options={clinicsArray}
        />
      </Wrapper>
    </Theme>
  )
}

export { ClinicSearchHero }

const SearchHolder = styled.div`
  display: none;

  ${({ theme }) => theme.media.md} {
    width: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    margin-top: 1.25rem;
  }
`

const SearchHolderMobile = styled.div`
  display: block;
  width: 100%;

  ${({ theme }) => theme.media.md} {
    display: none;
  }
`

const WrapperImages = styled.div`
  display: flex;
  overflow: hidden;
  gap: 1.25rem;
  padding: 0rem 0rem 2.5rem 12rem;
  z-index: 9;

  ${({ theme }) => theme.media.md} {
    padding: 0rem 0rem 3.25rem 12rem;
  }
`

const WrapperRectangularImage = styled.div`
  display: block;
  position: relative;
  width: 37.5rem;
  height: 17.5rem;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  overflow: hidden;
  flex-shrink: 0;
`

const WrapperSquareImage = styled.div`
  display: block;
  position: relative;
  width: 17.3125rem;
  height: 17.5rem;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  overflow: hidden;
  flex-shrink: 0;
`

const WrapperRoundedImage = styled.div`
  display: none;
  position: relative;
  width: 37.5rem;
  height: 17.5rem;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  overflow: hidden;
  flex-shrink: 0;

  ${({ theme }) => theme.media.md} {
    display: block;
  }
`

const GradientDiv = styled.div`
  width: 17.5rem;
  height: 17.5rem;
  background: ${({ theme }) =>
    `linear-gradient(135deg, ${theme.colors.palette.gradient.yellow}, ${theme.colors.palette.gradient.coral})`};
  border-radius: 1.25rem;
`
